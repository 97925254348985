<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="采购单号" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="采购单号搜索"
                    class="namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择状态"
                    class="statec"
                    filterable
                    clearable

                >
                    <el-option
                        v-for="(v,i) in this.$store.state.state"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="使用情况" prop="use_state">
                <el-select
                    v-model="form.use_state"
                    placeholder="选择使用情况"
                    class="use_statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['未使用','已使用']"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="采购类型" prop="type">
                <el-select
                    v-model="form.type"
                    placeholder="选择采购类型"
                    class="typec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['行政类','招待类','非行政及招待']"
                        :key="i"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="企业搜索" prop="enterprise_id">
                <el-select
                    v-model="form.enterprise_id"
                    placeholder="选择企业"
                    class="enterprisec"
                    @change="(enterprise_id) => get_department(enterprise_id)"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="enterprise in AllEnterprise"
                        :key="enterprise.name"
                        :label="enterprise.name"
                        :value="enterprise.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请部门" prop="department_id">
                <el-select
                    v-model="form.department_id"
                    placeholder="选择部门"
                    class="departmentc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="department in this.departmentInEnterprise"
                        :key="department.name"
                        :label="department.name"
                        :value="department.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请人" prop="staff_id">
                <el-select
                    v-model="form.staff_id"
                    placeholder="选择申请人"
                    class="staff_idc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="staff in AllStaff"
                        :key="staff.name"
                        :label="staff.name"
                        :value="staff.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="内容搜索" prop="title_content">
                <el-input
                    v-model="form.title_content"
                    placeholder="搜索标题或内容"
                    class="title_contentc"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
            </el-form-item>
            <el-button
                v-if="$_has(add_auth)"
                type="success"
                plain
                round
                @click="addTeam"
                class="temp_add"
                size="medium"
                >添加采购申请</el-button
            >
        </el-form>
    </el-card>
</template>

<script>
import { department_list_request,staff_list_request,enterprise_list_request } from '@/network/list.js'

export default {
    name: 'PurchaseSearchBarComponent',
    data() {
        return {
            form: {
                name: '',
                state: '',
                use_state: '',
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                type: '',
                title_content: '',
                page: 1,
            },
            AllEnterprise: [],
            AllStaff: [],
            departmentInEnterprise: []
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        addTeam() {
            this.$emit('addTeam')
        },
        get_department(enterprise_id) {
            this.form.department_id = ''
            this.departmentInEnterprise = []
            if (enterprise_id) {
                department_list_request({ enterprise_id })
                    .then((s) => {
                        if (s.status === 0) {
                            this.departmentInEnterprise = s.result
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err)
                    })
            }
        },
        get_all_staff() {
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllStaff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
        get_all_enterprise() {
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {
        add_auth: String
    },
    created() {
        this.get_all_staff()
        this.get_all_enterprise()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>